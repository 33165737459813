/**
 * Main App Layout
 */

import PropTypes from "prop-types";

import React, { useState, useContext, useMemo, memo } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Navigation } from "../../Navigation";
import { SummaryBoxes } from "../../SummaryBoxes";
import { Dashboard } from "../../Dashboard";
import { Molecular } from "../../Molecular";
import { Clinical } from "../../Clinical";
import { Avatar } from "../../Avatar";
import { LandingContainer } from "../../Landing";
import { UserManuals } from "../../UserManuals";
import { ReleaseNotes } from "../../ReleaseNotes";
import { Agreement } from "../../Agreement";
import FeedBack from "../../Feedback";
import Footer from "../../Footer";
import { AvatarIDImport } from "../../AvatarIDImport";
import { STATE_KEY as USER_STATE_KEY, CBioPortalTerms, Account, ChangePassword } from "../../User";
import styled from "styled-components";
import { AbilityContext, Can, subjects } from "../../../ability";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../styles/app.scss";

const Wrapper = styled.div`
  margin: 2rem 0;
  padding: 1rem;
`;

const ContentContainer = styled.main`
  width: 85%;
  margin: auto;
  padding: 1.3rem 3.9rem;
  background-color: #ffffff;
  border-radius: 4px;
`;

export const Main = () => {
  //console.log("In Main");

  const user = useSelector(state => state[USER_STATE_KEY]);
  const { hasLimitedAccess, welcomeMessage, requiredSignature } = user;
  const ability = useContext(AbilityContext);

  return (
    <>
      <Navigation />

      {ability.can("view", subjects.SUMMARY_BOXES) && <SummaryBoxes />}
      {ability.can("view", subjects.FEEDBACK) && <FeedBack />}

      <Wrapper>
        <ContentContainer>
          <Switch>
            {ability.cannot("view", subjects.PAGE_DASHBOARD) && <Route path="/" exact component={LandingContainer} />}
            {ability.can("view", subjects.PAGE_DASHBOARD) && <Route path="/" exact component={Dashboard} />}
            {ability.can("view", subjects.PAGE_MOLECULAR) && <Route path="/molecular" component={Molecular} />}
            {ability.can("view", subjects.PAGE_CLINICAL) && <Route path="/clinical" component={Clinical} />}
            {ability.can("view", subjects.PAGE_AVATAR) && <Route path="/avatar" component={Avatar} />}
            {ability.can("view", subjects.APP_CBIOPORTAL) && <Route path="/cBioPortal" component={CBioPortalTerms} />}
            {ability.can("view", subjects.TOOLBOX) && <Route path="/avatarID" component={AvatarIDImport} />}
            <Route path="/agreement" component={Agreement} />
            <Route path="/manuals" component={UserManuals} />
            <Route path="/releasenotes" component={ReleaseNotes} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/changepassword" component={ChangePassword} />
          </Switch>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};
