import React from "react";
import { AgreementText } from "../../Agreement";
import styled from "styled-components";

export const Agreement = () => {
  return (
    <Wrapper>
      <AgreementText />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
`;
