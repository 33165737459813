import * as actionTypes from "./actionTypes";

export const setLoading = (loading) => {
  return {
    type: actionTypes.SET_LOADING,
    value: loading
  };
};

export const setLoaded = (loaded) => {
  return {
    type: actionTypes.SET_LOADED,
    value: loaded
  };
};

export const setAgreementData = (data) => {
  return {
    type: actionTypes.SET_AGREEMENT_DATA,
    value: data
  };
};
