import React, { useEffect, memo, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { STATE_KEY as AGREEMENT_STATE_KEY, AgreementText } from "../../Agreement";
import { GENERIC_OA_API_KEY } from "../../api";
import * as actions from "../actions";
import { setRequiredSignature } from "../../User";
import { useSession, useAnalytics } from "../../../hooks";
import Markdown from "react-remarkable";
import styled from "styled-components";
import axios from "axios";

export const DataUseCertification = () => {
  const agreementSelector = useSelector(state => state[AGREEMENT_STATE_KEY]);
  const userSelector = useSelector(state => state[USER_STATE_KEY]);
  const dispatch = useDispatch();
  const { dataUsageText } = agreementSelector.agreement;
  const { endSession, updateSessionUser } = useSession();
  const { trackEvent } = useAnalytics();
  const [signature, setSignature] = useState("");
  const [isSigning, setIsSigning] = useState(false);

  const doesSignatureMatch = useMemo(() => {
    return userSelector.fullName.toLowerCase().trim() == signature.toLowerCase().trim();
  }, [signature, userSelector]);

  const handleDisagree = () => {
    trackEvent("Data Use Certification", "Disagreed", userSelector.userName);
    endSession();
  };

  const handleAgree = () => {
    const { ProfileID, fullName, title } = userSelector;
    const { versionID } = agreementSelector.agreement;

    setIsSigning(true);

    axios
      .request({
        method: "post",
        url: `https://${__API__}/user/${ProfileID}/agreement`,
        data: {
          versionID,
          profileID: ProfileID,
          fullName,
          title
        },
        headers: {
          "X-Api-Key": GENERIC_OA_API_KEY
        }
      })
      .then(response => {
        let updatedUser = {
          ...userSelector,
          requiredSignature: false
        };
        trackEvent("Data Use Certification", "Agreed", userSelector.userName);
        // this will update the user in session storage and redux
        updateSessionUser(updatedUser);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsSigning(false);
      });
  };

  return (
    <Wrapper>
      <Container>
        <Title>Data Use Certification</Title>
        <>
          {agreementSelector.loaded && !agreementSelector.agreement ? (
            <div>An error occurred, please refresh the page</div>
          ) : (
            <>
              <MarkdownWrapper>
                <AgreementText />
              </MarkdownWrapper>

              {agreementSelector.loaded && agreementSelector.agreement && (
                <SignatureSectionWrapper>
                  <SignatureLabel htmlFor="duc-electronic-signature">Enter the following name as your Electronic Signature:</SignatureLabel>
                  <SignatureName>{userSelector.fullName}</SignatureName>
                  <SignatureInput id="duc-electronic-signature" type="text" placeholder="" value={signature} onChange={e => setSignature(e.target.value)} autoFocus />
                  <SignatureButtonWrapper>
                    <SignatureButton onClick={handleDisagree}>Disagree</SignatureButton>
                    <SignatureAgreeButton disabled={!doesSignatureMatch || isSigning} onClick={handleAgree}>
                      Agree {isSigning && <Spinner />}
                    </SignatureAgreeButton>
                  </SignatureButtonWrapper>
                </SignatureSectionWrapper>
              )}
            </>
          )}
        </>
      </Container>
      <FooterWrapper>
        For support please contact <MailToLink href="mailto:support@m2gen.com">support@m2gen.com</MailToLink>
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;

  a {
    color: #0056b3;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Container = styled.main`
  border: 1px solid #ededed;
  padding: 2rem;
  margin: 1rem;
  border-radius: 8px;
  max-width: 750px;
  width: 100%;
  background-color: #fff;
  margin-top: 3rem;
`;

const Title = styled.h2`
  text-align: center;
  color: #25205c;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 1.8rem;
`;

const FooterWrapper = styled.footer`
  margin-top: 1rem;
  color: #555555;
`;

const MailToLink = styled.a`
  color: #555555 !important;
`;

const MarkdownWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
  border-bottom: 2px solid #eeeeee;
  font-size: 14px;
`;

const SignatureSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff0014;
  padding: 1.8rem 0;
`;

const SignatureLabel = styled.div`
  color: #25205c;
  font-weight: 500;
  font-size: 1.7rem;
`;

const SignatureName = styled.div`
  color: #007cbb;
  font-weight: 500;
  font-size: 2.2rem;
  margin: 0.7rem;
`;

const SignatureInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #007cbb;
  width: 450px;
  font-size: 2rem;
`;

const SignatureButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SignatureButton = styled.button`
  margin: 0.5rem;
  padding: 0.5rem;
  min-width: 150px;
  border-radius: 5px;
`;

const SignatureAgreeButton = styled(SignatureButton)`
  color: #fff;
  background-color: #007cbb;
`;

export const Spinner = styled.span`
  position: relative;
  margin: 0 1em;

  &::before {
    content: "";
    animation: 1000ms linear infinite spinner-rotate;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    border: dashed 3px transparent;
    border-bottom-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spinner-rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
