import * as actionTypes from "./actionTypes";

const defaultState = {
  loading: false,
  loaded: false,
  agreement: {
    typeID: "",
    versionID: "0",
    dataUsageText: ""
  }
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };
  switch (action.type) {
    case actionTypes.SET_AGREEMENT_DATA: {
      newState.agreement = action.value;
      return newState;
    }
    case actionTypes.SET_LOADING: {
      newState.loading = action.value;
      return newState;
    }
    case actionTypes.SET_LOADED: {
      newState.loaded = action.value;
      return newState;
    }
    default:
      return state;
  }
};
