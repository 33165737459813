import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { STATE_KEY as AGREEMENT_STATE_KEY } from "../../Agreement";
import { GENERIC_OA_API_KEY } from "../../api";
import ContentLoader from "react-content-loader";
import * as actions from "../actions";
import Markdown from "react-remarkable";
import axios from "axios";

export const AgreementText = () => {
  const agreementSelector = useSelector(state => state[AGREEMENT_STATE_KEY]);
  const dispatch = useDispatch();
  const { dataUsageText } = agreementSelector.agreement;

  const getDUA = () => {
    dispatch(actions.setLoading(true));
    dispatch(actions.setLoaded(false));

    axios
      .request({
        method: "get",
        url: `https://${__API__}/agreements/latest`,
        headers: {
          "X-Api-Key": GENERIC_OA_API_KEY
        }
      })
      .then(({ data }) => {
        if (data && data.dataUsageText) {
          dispatch(actions.setAgreementData(data));
          dispatch(actions.setLoaded(true));
        }
      })
      .catch(error => {
        dispatch(actions.setLoaded(false));
      })
      .then(() => {
        dispatch(actions.setLoading(false));
      });
  };

  useEffect(() => {
    if (!agreementSelector.loaded) {
      getDUA();
    }
  }, []);

  return <>{!agreementSelector.loaded ? <SkeletonContentText /> : <Markdown source={dataUsageText} />}</>;
};

const SkeletonContentText = () => {
  return (
    <ContentLoader viewBox="0 0 700 220">
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="99" rx="5" ry="5" width="400" height="100" />
    </ContentLoader>
  );
};
